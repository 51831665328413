<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 考试查询 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" />
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-input placeholder="请输入身份证号" v-model="idcard" />
        <a-select
          mode="multiple"
          placeholder="请选择身份"
          v-model="userType"
          showArrow
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">普通用户</a-select-option>
          <a-select-option :value="2">家长</a-select-option>
          <a-select-option :value="3">从业者</a-select-option>
          <!-- <a-select-option :value="4">机构</a-select-option> -->
        </a-select>
        <a-select
          mode="multiple"
          placeholder="请选择报名人员来源"
          v-model="tripartUser"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">华夏</a-select-option>
          <a-select-option value="2">恩启</a-select-option>
          <a-select-option value="3">ALSOLIFE</a-select-option>
        </a-select>
        <a-input placeholder="请输入考试名称" allowClear v-model="examName" />
        <a-select placeholder="请选择考试状态" allowClear v-model="examStatus">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">未考试</a-select-option>
          <a-select-option value="2">考试中</a-select-option>
          <a-select-option value="3">已通过</a-select-option>
          <a-select-option value="4">未通过</a-select-option>
        </a-select>
        <a-select placeholder="请选择照片提交方式" allowClear v-model="mode">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">邮寄纸质照片</a-select-option>
          <a-select-option value="2">使用上传电子照片</a-select-option>
        </a-select>
        <a-select
          placeholder="请选择信息确认状态"
          allowClear
          v-model="isConfirm"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="0">待确认</a-select-option>
          <a-select-option value="1">已确认</a-select-option>
        </a-select>
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          @change="onTimeChange"
          :placeholder="['考试开始时间', '考试结束时间']"
        />
        <a-range-picker
          @change="onTimeConfirm"
          :placeholder="['信息确认开始时间', '信息确认结束时间']"
        />
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          @change="onTimeAudit"
          :placeholder="['审核开始时间', '审核结束时间']"
        />
        <a-button
          type="primary"
          class="btn"
          :loading="loading"
          @click="onSearch"
          >搜索</a-button
        >
        <a-button type="primary" class="btn" @click="isExportShow = true"
          >导出</a-button
        >
        <a-button type="primary" class="btn" @click="onExportLogistic"
          >导出邮寄照片物流</a-button
        >
        <a-button type="primary" class="btn" @click="onExportPicture"
          >导出考试抓拍图片</a-button
        >
        <!-- <a-button type="primary" class="btn" @click="onExportPaper">导出用户考试试卷</a-button> -->
        <a-button type="primary" class="btn" @click="onExportCertifimobile"
          >导出电子照片</a-button
        >
        <a-button type="primary" class="btn" @click="goPagemodel"
          >配置证书模板</a-button
        >
        <a-button type="primary" class="btn" @click="onexportTemplate"
          >导出证书模板</a-button
        >
        <a-button type="primary" class="btn" @click="pushimg"
          >导出证书图片</a-button
        >
        <a-button type="primary" class="btn" @click="exportPaper"
          >导出考生试卷</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="examColumns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </div>
    <!-- 导出弹窗 -->
    <Derive
      ref="export"
      :visible="isExportShow"
      :handleCancel="modelCancel"
      @onexportList="onExportList()"
      :dataList="[
        {
          title: '报名信息',
          options: exportList,
        },
      ]"
    />

    <!-- 导出考生试卷弹框 -->
    <a-modal
      title="导出考生试卷"
      :visible="exportPaperVisible"
      :confirm-loading="confirmLoading"
      :closable="false"
      okText="确认"
      @cancel="handleCancel"
      @ok="onSubmit"
    >
      <a-form-model
        ref="ruleForm"
        v-if="exportPaperVisible"
        :model="exportPaperForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          ref="phone"
          label="手机号"
        >
          <a-input placeholder="请输入手机号" v-model="exportPaperForm.phone" />
        </a-form-model-item>
        <a-form-model-item ref="configId" label="考试名称" prop="configId">
          <a-select
            allowClear
            show-search
            v-model="exportPaperForm.configId"
            placeholder="请输入考试名称并选择"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-option v-for="item in examList" :key="item.configId">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="timeId" label="选择考试场次" prop="timeId">
          <a-select
            allowClear
            show-search
            v-model="timeText"
            placeholder="请选择考试场次"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              :value="item.examStartDate"
              v-for="item in examTimeList"
              :key="item.timeId"
            >
              {{ item.examStartDate }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Derive },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      keyword: "",
      name: "",
      mobile: "",
      idcard: "",
      userType: undefined,
      tripartUser: undefined,
      examName: undefined,
      examStatus: undefined,
      mode: undefined, //照片提交方式
      isConfirm: undefined,
      startDate: "",
      endDate: "",
      confirmStartTime: "",
      confirmEndTime: "",
      startAuditTime: "",
      endAuditTime: "",
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      examColumns: [], // 表头
      examSearchColumns: [
        {
          title: "序号",
          width: "80px",
          align: "center",
          dataIndex: "index",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "姓名",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "手机号",
          align: "center",
          dataIndex: "mobile",
        },
        {
          title: "身份证号",
          align: "center",
          dataIndex: "idcard",
        },
        {
          title: "考试名称",
          align: "center",
          dataIndex: "examName",
        },
        {
          title: "考试日期",
          align: "center",
          dataIndex: "examDate",
        },
        {
          title: "考试场次",
          align: "center",
          dataIndex: "examDateTime",
        },
        // {
        //   title: "考试成绩",
        //   align:'center',
        //   dataIndex: "examScore"
        // },
        {
          title: "总成绩",
          align: "center",
          dataIndex: "totalScore",
        },
        {
          title: "信息确认状态",
          align: "center",
          dataIndex: "isConfirmText",
        },
        {
          title: "照片提交方式",
          align: "center",
          dataIndex: "modeType",
        },
        {
          title: "考试状态",
          align: "center",
          dataIndex: "examStatus",
        },
      ],

      isExportShow: false, // 导出弹窗,
      exportPaperVisible: false, // 导出考生试卷弹框
      exportPaperForm: {
        
      },
      timeText: undefined, // 
      modelkeyword: '', // 考试名称输入文本
      examList: [], // 模糊搜索考试列表
      examTimeList: [], // 场次列表
      tiemID: null, // 定时器
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      confirmLoading: false, // 导出考生试卷loading
      exportList: [
        { name: "用户姓名", value: "name", checked: true, disabled: false },
        { name: "手机号", value: "mobile", checked: true, disabled: false },
        { name: "身份证", value: "idcard", checked: true, disabled: false },
        { name: "性别", value: "sexText", checked: true, disabled: false },
        { name: "学历", value: "education", checked: true, disabled: false },
        {
          name: "考试开始时间",
          value: "startTime",
          checked: true,
          disabled: false,
        },

        {
          name: "考试结束时间",
          value: "endTime",
          checked: true,
          disabled: false,
        },
        { name: "考试时长", value: "duration", checked: true, disabled: false },
        { name: "总分", value: "totalScore", checked: true, disabled: false },
        { name: "职称/职务", value: "post", checked: true, disabled: false },
        { name: "生日", value: "birthday", checked: true, disabled: false },

        { name: "工作单位", value: "workUnit", checked: true, disabled: false },
        { name: "所在地", value: "address", checked: true, disabled: false },
        { name: "用户名", value: "userName", checked: true, disabled: false },
        { name: "身份", value: "userTypeText", checked: true, disabled: false },
        {
          name: "考试状态",
          value: "examStatusText",
          checked: true,
          disabled: false,
        },
        { name: "课程", value: "courseName", checked: true, disabled: false },

        { name: "考试名称", value: "examName", checked: true, disabled: false },
        { name: "考试日期", value: "examDate", checked: true, disabled: false },
        {
          name: "考试场次",
          value: "examDateTime",
          checked: true,
          disabled: false,
        },
        {
          name: "信息确认状态",
          value: "isConfirmText",
          checked: true,
          disabled: false,
        },
        {
          name: "是否为第三方考试人员",
          value: "tripartUserText",
          checked: true,
          disabled: false,
        },
        { name: "补考次数", value: "examNum", checked: true, disabled: false },

        {
          name: "物流公司",
          value: "logisticsCompany",
          checked: true,
          disabled: false,
        },
        {
          name: "物流单号",
          value: "logisticsCode",
          checked: true,
          disabled: false,
        },
        {
          name: "确认信息时间",
          value: "confirmTime",
          checked: true,
          disabled: false,
        },
      ],
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 选择时间
    onTimeChange(date, dateString) {
      this.startDate = dateString[0]; // 开始日期
      this.endDate = dateString[1]; // 结束日期
    },
    onTimeConfirm(date, dateString) {
      this.confirmStartTime = dateString[0]; // 开始日期
      this.confirmEndTime = dateString[1]; // 结束日期
    },
    onTimeAudit(date, dateString) {
      this.startAuditTime = dateString[0]; // 开始日期
      this.endAuditTime = dateString[1]; // 结束日期
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    // 详情
    goPage() {
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },
    // 打开导出试卷弹框
    exportPaper() {
      this.exportPaperVisible = true;
    },
    // 取消导出
    handleCancel(){
      this.exportPaperVisible = false;
    },
    handleSearch(e) {
      this.modelkeyword = e;
      this.examList = [];
      this.getExamList();
    },

    // 获取考试列表
    getExamList() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if (!this.modelkeyword){
          return;
        }
        this.$ajax({
          url: "/hxclass-management/exam/config/getExamConfigList",
          method: "get",
          params: {
            name: this.modelkeyword
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.examList = res.data;
            if(!res.data.length){
              this.$message.error('未搜到相关考试');
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 1000);
    },
    handleChange(e) {
      this.timeText = undefined
      this.examTimeList = [];
      if(!e) {
        return
      }
      this.$ajax({
        url: "/hxclass-management/exam/signup/getExamTimeList",
        method: "get",
        params: {
          configId: e
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examTimeList = res.data;
          if(!res.data.length){
            this.$message.error('未搜到相关考试场次');
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导出试卷
    onSubmit() {
      let e = this.exportPaperForm;
      if(!e.phone && !e.configId) {
        return this.$message.warning('请先输入手机号或考试名称');
      }
      let phoneText = /^1[3-9]\d{9}$/; // 手机号正则
      if(e.phone && !phoneText.test(e.phone)) {
        return this.$message.warning('请输入正确的手机号');
      }

      if(this.timeText) {
        this.examTimeList.some(item=>{
          if(item.examStartDate == this.timeText) {
            this.exportPaperForm.timeId = item.timeId
          }
          return item.examStartDate == this.timeText
        })
      }else{
        this.exportPaperForm.timeId = ''
      }
      
      window.open(
        this.$config.target +
          "/hxclass-management/exam/examinee/exportExamineePaper?configId=" +
          encodeURIComponent(this.exportPaperForm.configId || '') +
          "&mobile=" +
          encodeURIComponent(this.exportPaperForm.phone || '') +
          "&timeId=" +
          encodeURIComponent(this.exportPaperForm.timeId || '')
      );
    },
    // 证书配置列表
    goPagemodel() {
      this.$router.push({ path: "/admin/Search/Templatepage" });
    },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList();
    },
    // 导出功能
    onExportList() {
      let filter = this.exportList.filter((item) => {
        return item.checked;
      });
      let arr = filter?.map((item, index) => {
        if (item.checked) {
          return {
            name: item.name,
            value: item.value,
          };
        }
      });
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/exam-query-export?columnJson=" +
          encodeURIComponent(JSON.stringify(arr)) +
          "&name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&examStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? String(this.mode) : "") +
          "&isConfirm=" +
          encodeURIComponent(this.isConfirm ? String(this.isConfirm) : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    // 导出物流
    onExportLogistic() {
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/signup-query-logistic-export?name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&auditStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? this.mode : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&current=" +
          encodeURIComponent(this.pageNumber) +
          "&size=" +
          encodeURIComponent(this.pageSize) +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    // 导出抓拍
    onExportPicture() {
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/signup-query-picture-export?name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&auditStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? this.mode : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&current=" +
          encodeURIComponent(this.pageNumber) +
          "&size=" +
          encodeURIComponent(this.pageSize) +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    // 导出试卷
    // onExportPaper() {
    //   window.open("http://6usglnvs.dnat.tech:38434/hxclass-management/exam/signup/exam-query-paper-export?keyword=" + encodeURIComponent(this?.keyword) +
    //   "&userType=" + encodeURIComponent(this.userType?this.userType:'') +
    //   "&tripartUser=" + encodeURIComponent(this.tripartUser?this.tripartUser:'') +
    //   "&examName=" + encodeURIComponent(this.examName==undefined?'':this.examName)  +
    //   "&examStatus=" + encodeURIComponent(this.examStatus==undefined?'':this.examStatus)  +
    //   "&isConfirm=" + encodeURIComponent(this.isConfirm?String(this.isConfirm):'') +
    //   "&startDate=" + encodeURIComponent(this?.startDate) +
    //   "&endDate=" + encodeURIComponent(this?.endDate) +
    //   "&confirmStartTime=" + encodeURIComponent(this?.confirmStartTime) +
    //   "&confirmEndTime=" + encodeURIComponent(this?.confirmEndTime) +
    //   "&current=" + encodeURIComponent(this?.pageNumber) +
    //   "&size=" + encodeURIComponent(this?.pageSize)
    //   );
    // },
    // 导出电子
    onExportCertifimobile() {
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/signup-query-certifimobile-export?name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&examStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? this.mode : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&current=" +
          encodeURIComponent(this.pageNumber) +
          "&size=" +
          encodeURIComponent(this.pageSize) +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    // 导出模板
    onexportTemplate() {
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/signup/test?name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&auditStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? this.mode : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&current=" +
          encodeURIComponent(this.pageNumber) +
          "&size=" +
          encodeURIComponent(this.pageSize) +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    // 导出证书图片
    pushimg() {
      window.open(
        this.$config.target +
          "/hxclass-management/exam/signup/yykszs-export?name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(this.userType ? this.userType : "") +
          "&tripartUser=" +
          encodeURIComponent(this.tripartUser ? this.tripartUser : "") +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&auditStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&mode=" +
          encodeURIComponent(this.mode ? this.mode : "") +
          "&startDate=" +
          encodeURIComponent(this.startDate ? this.startDate : "") +
          "&endDate=" +
          encodeURIComponent(this.endDate ? this.endDate : "") +
          "&current=" +
          encodeURIComponent(this.pageNumber) +
          "&size=" +
          encodeURIComponent(this.pageSize) +
          "&confirmStartTime=" +
          encodeURIComponent(this?.confirmStartTime) +
          "&confirmEndTime=" +
          encodeURIComponent(this?.confirmEndTime) +
          "&startAuditTime=" +
          encodeURIComponent(this?.startAuditTime) +
          "&endAuditTime=" +
          encodeURIComponent(this?.endAuditTime)
      );
    },
    getExport() {
      //没有200信息，无数据则为空
      this.$ajax({
        url: "/hxclass-management/exam/mark/config/all-list",
      }).then((res) => {
        res.forEach((item, index) => {
          this.exportList.splice(this.examColumns.length - 4, 0, {
            name: item.name,
            value: "name" + index,
            checked: true,
            disabled: false,
          });
        });
      });
    },
    getList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/exam/signup/exam-query",
        params: {
          name: this.name,
          mobile: this.mobile,
          idcard: this.idcard,
          userType: String(this.userType),
          tripartUser: String(this.tripartUser),
          examName: this.examName,
          examStatus: this.examStatus,
          mode: this.mode, //照片提交方式
          isConfirm: this.isConfirm,
          startDate: this.startDate,
          endDate: this.endDate,
          confirmStartTime: this.confirmStartTime,
          confirmEndTime: this.confirmEndTime,
          startAuditTime: this.startAuditTime,
          endAuditTime: this.endAuditTime,
          current: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          let arry = JSON.stringify(this.examSearchColumns);
          this.examColumns = JSON.parse(arry);
          let columns = [];
          res.data.records.forEach((element) => {
            JSON.parse(element.scorerRules).forEach((subItem) => {
              columns.push(subItem.name);
            });
          });

          Array.from(new Set(columns)).forEach((element, index) => {
            let obj = {
              title: element,
              align: "center",
              dataIndex: "score" + (index + 1),
            };
            this.examColumns.splice(this.examColumns.length - 4, 0, obj);
          });

          Array.from(new Set(columns)).forEach((n, i) => {
            res.data.records.forEach((item) => {
              JSON.parse(item.scorerRules).forEach((subItem) => {
                if (subItem.name == n) {
                  item["score" + (i + 1)] = subItem.score;
                }
              });
            });
          });
          this.total = res.data.total;
          this.tableData = res.data.records.map((item, index) => {
            return { ...item, id: index };
          });
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
    this.getExport();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
  margin-bottom: 10px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
</style>
